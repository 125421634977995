import './App.css';
import ModalComponent from './components/ModalComponent';

function App() {
  return (
    <ModalComponent />
  );
}

export default App;
